.specific_page{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    background-color: #222737 !important;
    animation: backgroundAnimation 5s linear infinite;
}

.watermark {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.formDiv {
    position: relative;
    border-radius: 0.75rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    box-sizing: border-box;
    justify-content: flex-start;
    gap: 1rem;
    max-width: 100%;
    width: 450px;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    color: #333333;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.labelDiv {
    border-radius: 0.375rem;
    height: 2.5rem;
    padding-left: 1rem;
    border: 2px solid #a19b9b;
}

.buttonDiv {
    border-radius: 0.375rem;
    height: 35px;
    font-weight: 600;
    background: #fbc335;
    color: black;
    border-radius: 10px;
    border: none;
  }
  
  
  .buttonDiv:hover {
    background: #b88e25;
    color: white;
    transform: scale(1.005);
  }

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: #52c41a;
    margin-left: 0.5rem;
}

.invalid {
    color: #f5222d;
    margin-left: 0.5rem;
}

.instructions {
    font-size: 0.875rem;
    border-radius: 0.5rem;
    background: #f2f2f2;
    color: #555555;
    padding: 0.5rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.5rem;
}


.buttonDiv{
    border-radius: 0.375rem;
    height: 35px;
    background: #9f8b3b;
    color: white;
}

.buttonDivDisabled{
    border-radius: 0.375rem;
    height: 35px;
    background: #555451;
    color: #a29f9f;
}