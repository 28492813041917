.containerCard {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    z-index: 4;
  }
  .firstRowIcon {
    height: 20px;
    width: 20px;
  }
  .connectionCard {
    z-index: 2;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    align-items: baseline;
    background: whitesmoke;
    height: 250px;
    width: 450px;
    display: flex;
    flex-direction: column;
    position: fixed;
    border-radius: 15px;
    animation: fadeIn 1s;
    color: black;
    animation-fill-mode: forwards;
  }
  
  .connectionCard.fadeOut {
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
    z-index: 0;
  }
  
  .flexDiv{
    align-self: center;
    display: flex;
    gap: 100px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .rowConnection {
    display: flex;
    flex-direction: row;
    padding: 1em;
    gap: 25%;
    justify-content: flex-end;
    width: inherit;
  }
  .rowConnection h1 {
    font-size: 20px;
    color: #c8b568;
    font-weight: 900;
  }
  
  .columnConnection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px 20px 5px;
  }
  
  .columnConnection h1 {
    font-size: 15px;
    color: #1f4781;
    font-weight: 900;
    margin: 0;
  }
  
  .columnConnection p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .submitButton {
    width: fit-content;
    margin-top: 10px;
    padding: 8px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .submitButton2 {
    width: fit-content;
    margin-top: 10px;
    padding: 8px 20px;
    font-size: 14px;
    border: grey 1px solid;
    border-radius: 4px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .submitButton2:hover {
    background-color: grey;
    color: black;
  }

  .submitButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .submitButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }