.specific_page {
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    background-repeat: no-repeat;
    background: linear-gradient(to bottom, #222737, #2f3850);
}

.parentpStyle{
    color: #FFF;
    font-size: 25px;
    margin-bottom: 2rem;
}
.parentpStyle2{
    color: #FFF;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 30px;
}

.copyMessage {
    position: absolute;
   display: inline-block;
    background-color: #4f5b83;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}


.parentpStyle3{
    color: #FFF;
    cursor: pointer;
    font-weight: 600;
    font-size: 25px;
    transition: color 0.3s, transform 0.3s;
}
.parentpStyle3:hover{
    cursor: pointer;
    color: #849ce0;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: black;
    background: #fbc335;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s,color 0.3s, transform 0.2s;
}

.button:hover {
    background: #b88e25;
    color: white;
    transform: scale(1.05);
}


.logoIcon {
    position: relative;
    width: 400px;
    height: 400px;
  }

.parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.parentDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    flex-wrap: wrap;
    background: #FFF;
    height: 80px;
    text-align: center;
    font-size: 25px;
    cursor:pointer;
    transition: background-color 0.3s; 
}


.parentDiv:hover {
    color: #FFF;
    background-color: #4f5b83; 
}