/* Popup style */
.popupBox {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
}

.box {
    position: relative;
    width: 40%;
    height: 50%;
    margin: 0 auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 15px;
    border: 1px solid #999;
    overflow: auto;
}

.upperInnerBox {
    height: 50%;
    background: linear-gradient(350deg, #3f6fc2 0%, #1e3b70 64%) !important;

}

.bottomInnerBox {
    height: 50%;
    overflow: hidden;
}

.bottomLabel {
    color: black;
    margin: 0 auto;
    padding: 0px 30px;;
    width: inherit;
    display: block;
    text-align: center;
    margin-top: 5%;
}

.bottomLabel p {
    color: black;
    font-weight: bolder;
    text-align: center;
}

.closeButton {
    background: linear-gradient(350deg, #3f6fc2 0%, #1e3b70 64%) !important;
    border: none;
}

.closeButton:hover {
    background: linear-gradient(360deg, #d6d9df 0%, #1e3b70 50%);
}

.checkLogo {
    max-height: 80%;
    max-width: 80%;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}