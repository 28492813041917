.switch_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.switch_button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  border-radius: 34px;
  outline: none;
  transition: background-color 0.4s;
}

.slider.round {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  top: 4px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

.switch_button.active {
  background-color: #2196F3;
}

.switch_button:focus {
  box-shadow: 0 0 1px #2196F3;
}

.switch_button.active .slider.round {
  transform: translateX(26px);
}

.switch_label {
  font-size: 15px;
  width: 125px;
}
