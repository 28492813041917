.containerCard {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: 4;
}

.connectionCard {
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  align-items: baseline;
  background: whitesmoke;
  height: 650px;
  width: 450px;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 15px;
  animation: fadeIn 1s;
  color: black;
  animation-fill-mode: forwards;
}

.connectionCard.fadeOut {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
  z-index: 0;
}

.rowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: inherit;
  justify-content: space-between;
}

.columnConnection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px 20px 5px;
}

.columnConnection h1 {
  font-size: 15px;
  color: #1f4781;
  font-weight: 900;
  margin: 0;
}

.columnConnection p {
  font-size: 12px;
  margin-bottom: 5px;
}
.rowConnection > div:nth-child(2) {
  font-size: 20px;
  color: #c8b568;
  font-weight: 900;
}

.firstRowIcon {
  height: 20px;
  width: 20px;
}

.firstRowIcon:hover {
  cursor: pointer;
  color: #000000a8;
}

.flexDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.imgStyle {
  width: fit-content;
  align-self: center;
}

.label {
  font-size: 16px;
  color: #c8b568;
  font-weight: 800;
  margin-bottom: 5px;
}

.input {
  padding: 6px 12px;
  font-size: 16px;
  width: 305px;
  border-radius: 10px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: 30px;
}


.submitButton {
    width: fit-content;
    margin-top: 10px;
    padding: 8px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .submitButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }