.btc {
  position: relative;
  letter-spacing: 0.07em;
  line-height: 160%;
  font-weight: 500;
  width: inherit;
  text-align: center;
  z-index: 100;
}
.btc:hover {
  background-color: grey;
  color: black; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}
.scambiaDa {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  z-index: 100;
}
.dropExchange1 {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-darkgray);
  box-sizing: border-box;
  width: 260px;;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mini)  var(--padding-mini) 0px  var(--padding-mini);
  align-items: center;
  justify-content: center;
  gap: var(--gap-mini);
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-white);
  font-family: var(--font-poppins);
  z-index: 100;
}
