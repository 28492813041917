.selezionaAltroAddress {
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  width: 131px;
  height: 21px;
  flex-shrink: 0;
  font-size: 15px;
  font-weight: bold;
  width: inherit;
  align-self: center;
}
.coinType,
.mtdnscoxy,
.selezionaAltroAddress {
  position: relative;
  line-height: 100%;
}
.mtdnscoxy {
  font-weight: 500;
  color: var(--color-gray-100);
}
.vectorIcon {
  position: relative;
  width: 15.12px;
  height: 10.99px;
}
.dropmenuChild {
  position: relative;
  width: 202.3px;
  height: 0.3px;
}
.addressDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.mostraLeChiavi {
  position: relative;
  letter-spacing: 0.01em;
  font-weight: 600;
  cursor: pointer;
}
.button,
.dropmenu {
  border-radius: var(--br-5xs);
  display: flex;
  align-items: center;
}
.button {
  margin-top: 15px;
  background-color: var(--color-darkkhaki);
  backdrop-filter: blur(8px);
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-16xl);
  justify-content: center;
  font-size: var(--font-size-mini);
  color: var(--color-gray-100);
}
.dropmenu {
  position: relative;
  align-items: inherit;
  background-color: var(--color-white);
  flex-direction: column;
  padding: var(--padding-xs);
  box-sizing: border-box;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  width: inherit;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: 10.99px;
  color: var(--color-darkgoldenrod);
  font-family: var(--font-poppins);
}
