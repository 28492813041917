.containerCard {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    z-index: 4;
  }
  
  .connectionCard {
    z-index: 2;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    align-items: baseline;
    background: whitesmoke;
    height: auto;
    padding-bottom: 20px;
    width: 450px;
    display: flex;
    flex-direction: column;
    position: fixed;
    border-radius: 15px;
    animation: fadeIn 1s;
    color: black;
    animation-fill-mode: forwards;
  }
  
  .connectionCard.fadeOut {
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
    z-index: 0;
  }
.dropdownContent {
  max-width: 400px;
  padding: 5px 15px;
  background: #1f3251;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 150px;
  width: auto;
  border-radius: 5px;
  z-index: 1;
}

.dropdownClick {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.flexDivBal{
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: flex-end;
}

.dropdownContent::-webkit-scrollbar {
  width: 10px;
  background: #7775;
  border-radius: 15px;
}

.dropdownContent::-webkit-scrollbar-thumb {
  background: #7775;
  border-radius: 15px;
}

.dropdownContent::-webkit-scrollbar-thumb:hover {
  background: #7777;
}

.dropdownContent::-webkit-scrollbar-track {
  border-radius: 15px;
}

.dropdownContent::-webkit-scrollbar-track:hover {
  background: #3338;
}

.dropdownItem {
  padding: 10px;
  display: flex;
  cursor: pointer;
  transition: all 0.2s;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dropdownItem:hover {
  background: grey;
}

.dropdownItem > div:nth-child(1) {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dropdownItem > div:nth-child(1) {
  font-size: 20px;
  font-weight: 700px;
  padding-top: 10px;
}

.submitButton {
  padding: 8px 10px;
  font-size: 14px;
  border: none;
  width: fit-content;
  align-self: center;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copyPopup {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  z-index: 999;
}

.iconStyle {
  cursor: pointer;
}

.flexDivSecond {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.submitButton:hover {
  background-color: #0056b3;
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.spanStyle {
  color: #c8b568;
  font-weight: 900;
}

.break_word {
  word-wrap: break-word;
}

.imgStyle {
    width: fit-content;
    align-self: center;
    margin-bottom: 10px;
}

.columnConnection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px 20px 5px;
  }

.rowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: inherit;
  justify-content: space-between;
}
.rowConnection > div:nth-child(1) {
    flex: 0;
}

.rowConnection > div:nth-child(2) {
    flex: 1;
    margin-top: 0;
    align-self: center;
    text-align: center;
    padding-left: 23px;
    height: 20px;
    font-size: 20px;
    color: #c8b568;
    font-weight: 900;
}
.rowConnection > div:nth-child(2) {
    flex: 2;
}

.firstRowIcon {
  height: 20px;
  width: 20px;
}

.columnConnection h1{
 font-size: 15px;
  color: #1f4781;
  font-weight: 900;
  margin: 0;
}

.firstRowIcon:hover {
  cursor: pointer;
  color: #000000a8;
}
