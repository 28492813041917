.specific_page {
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    background-repeat: no-repeat;
    background: linear-gradient(to bottom, #222737, #37425e);
}

.parentpStyle{
    color: #FFF;
    font-size: 25px;
    margin-bottom: 2rem;
}

.childpStyle{
    color: rgb(214, 33, 33);
    font-size: 15px;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 10px;
}

.flexDiv{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.inputWrapper {
    position: relative;
}
.errorPopup {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f44336;
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
}


.specific_page h1 {
    margin-bottom: 20px;
    color: white;
}

.logoIcon {
    position: relative;
    width: 400px;
    height: 400px;
  }
  

.input_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.input_grid input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttonStyle {
    width: max-content;
    align-self: center;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background: #fbc335;
    color: black;
    font-weight: 600;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s,color 0.3s, transform 0.2s;
}

.buttonStyle:hover{
    background: #b88e25;
    color: white;
    transform: scale(1.05);
}