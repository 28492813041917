.privateKeys2 {
  line-height: 70%;
  text-transform: uppercase;
}
.privateKeys1 {
  margin-block-start: 0;
  margin-block-end: 2.74px;
}

.divImpostazioni {
  display: flex;
  flex-direction: column;
  gap: var(--gap-5xs);
}

.inputField {
  all: initial;
  height: inherit;
  width: inherit;
  color: white;
}
.nonCondividereMai {
  margin: 0;
  font-size: 11.88px;
  line-height: 162.5%;
  color: var(--color-white);
}
.privateKeysNonContainer1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-break: anywhere;
  width: 100%;
}

.privateKeysNonContainer4 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 532px;
  height: 70px;
  flex-shrink: 0;
  font-size: var(--font-size-6xl);
  color: var(--color-darkkhaki);
}

@media (max-width: 1285px) {
  .privateKeysNonContainer1 {
    width: 80%;
  }
}

.password,
.privateKeysNonContainer {
  position: relative;
  letter-spacing: 0.02em;
}
.privateKeysNonContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 532px;
  height: 103px;
  flex-shrink: 0;
  font-size: var(--font-size-6xl);
  color: var(--color-darkkhaki);
}
.password {
  line-height: 162.5%;
}
.vectorIcon {
  position: relative;
  width: 20px;
  height: 17px;
  cursor: pointer;
}
.passwordLabel {
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-cornflowerblue);
  box-sizing: border-box;
  width: 431px;
  height: 41px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: space-between;
  mix-blend-mode: normal;
}
.mostraLeChiavi {
  position: relative;
  letter-spacing: 0.01em;
  font-weight: 600;
}
.button,
.vuoiCambiareLa {
  display: flex;
  justify-content: center;
}
.button {
  border-radius: var(--br-5xs);
  background-color: var(--color-darkkhaki);
  backdrop-filter: blur(8px);
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-16xl);
  align-items: center;
  font-size: var(--font-size-mini);
  color: var(--color-gray-100);
  cursor: pointer;
}
.vuoiCambiareLa {
  position: relative;
  font-size: var(--font-size-smi);
  letter-spacing: 0.02em;
  line-height: 162.5%;
  text-align: center;
  align-items: flex-end;
  width: 532px;
  height: 103px;
  flex-shrink: 0;
}
.privateKeys {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  z-index: 2;
  text-align: left;
  font-size: 11.88px;
  color: var(--color-white);
  font-family: var(--font-poppins);
}

.language_button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkkhaki);
  font-size: 16px;
  color: var(--color-gray-100);
  border: 2px solid var(--color-darkkhaki); /* Aggiunto stile del bordo */
  cursor: pointer;
  outline: none;
}

.language_button.active {
  background-color: #ffffff;
  color: #c8b568;
  border-color: #c8b568; /* Aggiunto stile del bordo */
}

.divButton{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 250px;
}
