.riqCoinChild {
  position: absolute;
  top: 15px;
  left: 50px;
  border-radius: var(--br-5xs);
  border: 2px solid var(--color-white);
  box-sizing: border-box;
  width: 452px;
  height: 135px;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
  pointer-events: auto;
}

.riqCoinChild:hover {
background-color: rgba(255, 255, 255, 0.9);
}

.riqCoinChild:hover > .exchangeIcon,
.riqCoinChild:hover > .infoIcon {
  color: black;
  pointer-events: none;
  z-index: 2;
}

.exchangeIcon{
  transition: color 0.8s ease-in-out;
  color: white;
  position: absolute;
  top: 56px;
  left: 408px;
  cursor: pointer;
  z-index: 2;
}


.infoIcon{
  transition: color 0.8s ease-in-out;
  color: white;
  position: absolute;
  top: 57px;
  left: 342px;
  cursor: pointer;
  z-index: 2;
}

.warning {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f8f8;
  color: #333;
  padding: 8px 12px;
  border-radius: 4px;
  z-index: 999;
  opacity: 0;
  font-size: 14px;
  display: inline-block;    
  box-shadow: 8px 8px 10px rgb(0 0 0 / 50%);
  animation: fadeOut 5s ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}



.btcIcon {
  position: absolute;
  height: 75.09%;
  width: 20.74%;
  top: 14%;
  right: 81.29%;
  bottom: 19.91%;
  left: -8%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
  transform: scale(1) rotate(0deg);
}

.riqCoinChild:hover .btcIcon {
  transform: scale(1.2) rotate(360deg);
}
.bitcoin {
  position: absolute;
  top: 30px;
  left: 126px;
  letter-spacing: 0.01em;
  font-weight: 500;
  color: var(--color-white);
  transition: color 0.8s ease-in-out;
}
.bitcoinHover{
  position: absolute;
  top: 30px;
  left: 126px;
  letter-spacing: 0.01em;
  font-weight: 500;
  cursor: pointer;
  color: black;
}


.bilancio {
  font-size: 14px;
  font-weight: 300;
  transition: color 0.8s ease-in-out;
}

.addressVal{
  font-weight: 600;
  width: max-content;
  position: absolute;
  top: 20px;
  left: 0;
  transition: color 0.8s ease-in-out;
}
.addressValHover{
  color: var(--color-black);
  font-weight: 600;
  width: max-content;
  position: absolute;
  top: 20px;
  left: 0;
}

.bilancioHover {
  font-size: 14px;
  font-weight: 300;
  color: var(--color-black);
}
.spanHover,
.valoreHover {
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-black);
}

.span,
.valore {
  font-weight: 600;
  font-family: var(--font-poppins);
  transition: color 0.8s ease-in-out;
}

.supStyle{
  top: -.9em;
  left: -.4em;
  font-size: .6em;
  color:#c8b568;
}
.valore {
  font-weight: 300;
}
.bilancio0Valor {
  line-height: 143%;
}
.bilancio0Container {
  position: absolute;
  top: 75px;
  left: 128px;
  font-size: var(--font-size-xs);
  letter-spacing: 0.03em;
}
.p,
.prezzo {
  font-size: 14px;
  margin: 0;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 300;
  transition: color 0.8s ease-in-out;
}

.prezzoHover {
  margin: 0;
  font-weight: 300;
  color: var(--color-black);
  margin-bottom: 5px;
}

.p {
  font-size: var(--font-size-base);
  font-weight: 600;
  transition: color 0.8s ease-in-out;
}

.pHover{
  font-size: var(--font-size-base);
  font-weight: 600;
  color: var(--color-black);
}
.prezzo2762233 {
  position: absolute;
  z-index: 0;
  top: 120px;
  left: 97px;
  width: inherit;
  letter-spacing: 0.02em;
  line-height: 138.5%;
  text-align: right;
  font-size: var(--font-size-2xs);
}

.prezzo2762233_0{
  position: absolute;
  top: 57px;
  left: 430px;
  letter-spacing: 0.02em;
  line-height: 138.5%;
  text-align: right;
  font-size: var(--font-size-2xs);
}
.riqCoin {
  position: relative;
  width: 509px;
  height: 150px;
  text-align: left;
  font-size: var(--font-size-16xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}


@media screen and (min-width: 1520px) and (max-width: 1750px) {
  .btcIcon{
    height: 75.09%;
    width: 25.74%;
  }
  .riqCoinChild{
    position: absolute;
    top: 15px;
    left: 50px;
    border-radius: var(--br-5xs);
    border: 2px solid var(--color-white);
    box-sizing: border-box;
    width: 482px;
    height: 165px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    background-color: transparent;
  }
  .riqCoin{
    height: 180px;
  }

  /* DIV CENTRALI */
  .bitcoin{
    left: 150px;
    font-size: 40px;
  }
  .bilancio0Container{
    top: 75px;
    left: 150px;
    margin-top: 5px;
  }

  .prezzo2762233{
    top: 125px;
    left: 148px !important;
  }
  .prezzo{
    font-size: 20px;
  }
  .p{
    font-size: 18px;
    width: inherit;
  }

  .exchangeIcon{
    top: 69px;
    left: 443px;
  }

  .infoIcon{
    top: 70px;
    left: 382px;
  }
}