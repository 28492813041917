.logoIcon,
.walletIcon {
  position: relative;
  width: 222px;
  height: 163.21px;
}
.walletIcon {
  width: 25.6px;
  height: 22.6px;
}
.wallet1 {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 100%;
  font-weight: 500;
}
.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  cursor: pointer;
}
.menuChild {
  position: relative;
  width: 179.5px;
  height: 0.5px;
  mix-blend-mode: overlay;
}
.transazioniIcon {
  position: relative;
  width: 22.4px;
  height: 22.4px;
}
.transazioni {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 100%;
  cursor: pointer;
}
.storia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.supportoIcon {
  position: relative;
  width: 26.1px;
  height: 26.1px;
}
.supporto1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  color: var(--color-darkkhaki);
}
.esciIcon,
.impostazioniIcon {
  position: relative;
  width: 26.99px;
  height: 30px;
}
.esciIcon {
  width: 25px;
  height: 25px;
}
.esci1 {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 100%;
}
.menu,
.sidebarLeftTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
}
.sidebarLeftTop {
  border-radius: var(--br-mini);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  width: 300px;
  height: 650px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-21xl) var(--padding-6xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-50xl);
}
.eth,
.instantExchange,
.scambia {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 160%;
}
.instantExchange {
  font-size: var(--font-size-lg);
  font-weight: 600;
}
.eth,
.scambia {
  font-weight: 300;
}
.eth {
  letter-spacing: 0.07em;
  font-weight: 500;
}
.vectorIcon {
  position: relative;
  width: 10px;
  height: 5px;
}
.scambiaDa {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  cursor: pointer;
}
.scambiaChild {
  position: relative;
  border-right: 0.5px solid var(--color-darkgray);
  box-sizing: border-box;
  width: 0.5px;
  height: 42.5px;
}
.b {
  position: relative;
  letter-spacing: 0.07em;
  line-height: 160%;
  font-weight: 300;
  color: var(--color-gray-200);
}
.scambia1,
.scambiaA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.scambia1 {
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-darkgray);
  overflow: hidden;
  padding: 0 var(--padding-lg);
  gap: var(--gap-mini);
  font-size: var(--font-size-mid);
}
.scambiaA {
  gap: var(--gap-14xl);
  cursor: pointer;
}
.eth0000088,
.mostraLeChiavi {
  position: relative;
  letter-spacing: 0.01em;
}
.eth0000088 {
  line-height: 160%;
}
.mostraLeChiavi {
  font-weight: 600;
}
.button {
  border-radius: var(--br-5xs);
  background-color: var(--color-darkkhaki);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-16xl);
  align-items: center;
  justify-content: center;
  color: var(--color-gray-100);
}
.exchange,
.sidebarLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.exchange {
  border-radius: var(--br-mini);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 330px;
  height: 400px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-40xl) var(--padding-6xl);
  align-items: center;
  gap: var(--gap-xl);
  opacity: 0.95;
  mix-blend-mode: normal;
  font-size: var(--font-size-mini);
}
.sidebarLeft {
  align-items: flex-start;
  gap: var(--gap-4xl);
  z-index: 0;
}
.supportoChild {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 359px;
  width: 2px;
  height: 738px;
  mix-blend-mode: overlay;
  z-index: 1;
}
.base {
  height: 83.72%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 16.28%;
  left: 0;
}
.base,
.emailUs,
.icon {
  position: absolute;
}
.emailUs {
  top: calc(50% - 26.16px);
  left: 33.73%;
  font-size: 14.62px;
  letter-spacing: 1px;
  line-height: 19.5px;
  text-transform: uppercase;
  font-weight: 600;
}
.icon {
  height: 19.4%;
  width: 15.83%;
  top: 12.76%;
  right: 42.08%;
  bottom: 67.84%;
  left: 42.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.inviaciUnaMail {
  margin: 0;
}
.emailUsForContainer {
  position: absolute;
  width: 91.17%;
  top: calc(50% + 4.47px);
  left: 4.58%;
  line-height: 17.23px;
  color: var(--color-white);
  display: inline-block;
  height: 49px;
}
.hellohelpcentercom {
  position: absolute;
  top: calc(50% + 72.53px);
  left: 21.2%;
  line-height: 16.57px;
  font-weight: 600;
  cursor: pointer;
}
.item {
  position: relative;
  width: 219.37px;
  height: 179.06px;
}
.contatti {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.frameChild {
  position: relative;
  border-radius: var(--br-50xl-7);
  width: 24.89px;
  height: 24.89px;
  cursor: pointer;
}
.contattiFaq,
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9.96px;
}
.contattiFaq {
  flex-direction: column;
  padding: 61px 0;
  align-items: center;
  gap: 19px;
  z-index: 2;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-darkkhaki);
}
.supporto {
  position: relative;
  background: linear-gradient(108.67deg, #222737, #1560bd), #1560bd;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xl) 0 var(--padding-11xl) var(--padding-11xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
