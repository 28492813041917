.logoIcon,
.walletIcona {
  position: relative;
  width: 300px;
  height: 300px;
}

.walletIcona {
  width: 25.6px;
  height: 22.6px;
}
.wallet1 {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 100%;
}
.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  color: white;
  cursor: pointer;
}

.wallet.active {
  color: #c8b568;
}

.menuChild {
  position: relative;
  width: 179.5px;
  height: 0.5px;
  mix-blend-mode: overlay;
}
.storiaIcon {
  position: relative;
  width: 22.4px;
  height: 22.4px;
}
.transazioni {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 100%;
}
.storia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  cursor: pointer;
}

.storia.active {
  color: #c8b568;
}

.impostazioni {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  cursor: pointer;
}

.impostazioni.active {
  color: #c8b568;
}

.supporto {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  cursor: pointer;
}

.supporto.active {
  color: #c8b568;
}

.supportoIcon {
  position: relative;
  width: 26.1px;
  height: 26.1px;
}
.esciIcon,
.impostazioniIcon {
  position: relative;
  width: 26.99px;
  height: 30px;
}
.esciIcon {
  width: 25px;
  height: 25px;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
}
.sidebarLeft,
.sidebarLeftTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.sidebarLeftTop {
  border-radius: var(--br-mini);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  width: 300px;
  height: 680px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0px 25px 40px 25px;
  box-sizing: border-box;
  align-items: center;
  gap: 20px;
}
.sidebarLeft {
  align-items: flex-start;
  gap: var(--gap-4xl);
  z-index: 0;
}
.dashboardChild {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 359px;
  width: 2px;
  height: 738px;
  mix-blend-mode: overlay;
  z-index: 1;
}
.dashboardChild2 {
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  right: 359px;
  width: 2px;
  height: 738px;
  mix-blend-mode: overlay;
  z-index: 1;
  transform: rotate(180deg);
}

.coinWalletStyle,
.dashboard {
  display: flex;
  align-items: flex-start;
}
.coinWalletStyle,.coinWalletStyle2::-webkit-scrollbar {
  width: 1.5vh;
}
.coinWalletStyle,.coinWalletStyle2::-webkit-scrollbar-thumb {
  background-color: #14376447;
  border-radius: 8px;
}

/* Stile specifico per la barra di scorrimento */
.coinWalletStyle,.coinWalletStyle2::-webkit-scrollbar-track {
  background-color: linear-gradient(108.67deg, #222737, #1560bd);
  border-radius: 8px;
}
.coinWalletStyle,.coinWalletStyle2::-webkit-scrollbar-thumb:hover {
  background: #222737; /* Colore del thumb al passaggio del mouse */
}

.coinWalletStyle{
  width: auto;
  height: 800px;
  overflow-x: hidden;
  flex-direction: column;
  padding: 26px 0;
  z-index: 2;
  gap: 150px;
}

.coinWalletStyle2{
  width: auto;
  height: 800px;
  overflow-x: hidden;
  flex-direction: column;
  padding: 26px 0;
  z-index: 2;
  gap: 20px;
}

.dashboard {
  position: fixed;
  background: linear-gradient(108.67deg, #222737, #1560bd), #1560bd;
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow: auto;
  overflow-x: hidden;
  padding: var(--padding-11xl) 0 var(--padding-11xl) var(--padding-11xl);
  box-sizing: border-box;
  justify-content: space-between;
  opacity: 1;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.dashboard.animate {
  animation: 1s ease 0s 1 normal forwards scale-up;
}


.dashboard::-webkit-scrollbar{
  width: 1.5vh;
}

.dashboard::-webkit-scrollbar-thumb{
  background: #7775;
  border-radius: 15px;
}

.dashboard::-webkit-scrollbar-thumb:hover{
  background: #7777;
}

.dashboard::-webkit-scrollbar-track{
  border-radius: 15px;
}

.dashboard::-webkit-scrollbar-track:hover{
  background: #3338;
}


@media screen and (min-width: 1520px) and (max-width: 1750px) {
  .dashboardChild{
    left: 445px;
  }

  .logoIcon{
    width: 285px;
    height: 289px;
  }

  .sidebarLeft{
    font-size: 20px;
  }
  .walletIcona{
    width: 35px;
    height: 35px;
  }
  .storiaIcon{
    width: 35px;
    height: 35px;
  }
  .esciIcon{
    width: 35px;
    height: 35px;
  }
  .supportoIcon{
    width: 35px;
    height: 35px;
  }
  .impostazioniIcon{
    width: 35px;
    height: 35px;
  }
  .coinWalletStyle{
    width: 560px;
    height: 900px;
    overflow: auto;
    flex-direction: column;
    padding: 26px 0;
    z-index: 2;
    gap: 170px;
  }

  .coinWalletStyle2{
    height: 800px;
    width: 555px;
    overflow: auto;
    flex-direction: column;
    padding: 26px 0;
    z-index: 2;
    gap: 170px;
  }
  .sidebarLeftTop{
    width: 400px;
    height: 750px;
  }
}
