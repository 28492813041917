.connectionCard {
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  align-items: center;
  background: whitesmoke;
  height: 415px;
  width: 450px;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 30px;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

.connectionCard.active {
  height: 650px;
}

.connectionCard.inactive {
  height: 500px;
}

.connectionCardLoading {
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  align-items: center;
  background: whitesmoke;
  height: 200px;
  width: 370px;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 30px;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

/* Stile per il label */
.label {
  font-size: 16px;
  color: #c8b568;
  font-weight: 800;
  margin-bottom: 5px;
}
.secondRowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: inherit;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  border: solid 1px #6c6c6c;
  border-radius: 15px;
}
/* Stile per l'input */
.input {
  padding: 8px 12px;
  font-size: 16px;
  width: 350px;
  border-radius: 10px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.dropdown {
  width: 400px;
  user-select: none;
}

.dropdownBtn {
  padding: 0px 15px 15px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dropdownClick {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownBtnSecond {
  padding: 0px 35px 5px 35px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.dropdownContent {
  padding: 5px 15px;
  background: #1f3251;
  box-shadow: 3px 3px 15px 6px rgb(18 22 47 / 50%);
  color: white;
  overflow-y: scroll;
  height: 150px;
  width: 214px;
  border-radius: 5px;
  z-index: 1;
}

.dropdownContent::-webkit-scrollbar {
  width: 10px;
  background: #7775;
  border-radius: 15px;
}

.dropdownContent::-webkit-scrollbar-thumb {
  background: #7775;
  border-radius: 15px;
}

.dropdownContent::-webkit-scrollbar-thumb:hover {
  background: #7777;
}

.dropdownContent::-webkit-scrollbar-track {
  border-radius: 15px;
}

.dropdownContent::-webkit-scrollbar-track:hover {
  background: #3338;
}

.dropdownItem {
  padding: 10px;
  display: flex;
  cursor: pointer;
  transition: all 0.2s;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dropdownItem:hover {
  background: grey;
}

.dropdownItem > div:nth-child(1) {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dropdownItem > div:nth-child(1) {
  font-size: 20px;
  font-weight: 700px;
  padding-top: 10px;
}

.swapCardSecondRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  width: 370px;
  background: #111632;
  text-align: center;
  border-radius: 30px;
}

.inputStyle {
  all: unset;
  text-align: right;
  height: 50px;
  font-size: 20px;
  color: white;
}

.formControl {
  width: 200px;
}

.labelForm {
  display: block;
  text-align: center;
}

.submitButton {
  padding: 12px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #0056b3;
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.textMuted {
  text-align: left;
  width: 350px;
  font-size: smaller;
}

.controlForm {
  width: 300px;
}

.containerCard {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: 4;
}

.containerCard.blurOut {
  z-index: -1;
}

.connectionCard.fadeOut {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
  z-index: 0;
}

.firstRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  width: inherit;
  border-bottom: 1px solid #d4cfcf;
  background: linear-gradient(130deg, #7ba2de, #eaf2ff);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.firstRowH1 {
  font-size: 18px;
  margin-right: 80px;
}

.firstRowIcon {
  height: 20px;
  width: 20px;
}

.firstRowIcon:hover {
  cursor: pointer;
  color: #000000a8;
}

.rowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: inherit;
  justify-content: space-between;
}

.rowConnection > div:nth-child(2) {
  font-size: 20px;
  color: #c8b568;
  font-weight: 900;
}
.secondRowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
  border: solid 1px #6c6c6c;
  cursor: pointer;
  border-radius: 15px;
}
.secondRowConnection.alt {
  border: solid 1px #6c6c6c;
  background-color: rgb(213 214 218);
  justify-content: space-between;
  height: 70px;
}

.secondRowConnection:hover .cryptoImage {
  transform: rotate(45deg);
}

.flexDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 130px;
}

.flexDivSecond {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.flexDiv.cont {
  align-items: center;
  border: solid 1px #6c6c6c;
  background-color: rgb(213 214 218);
  border-radius: 15px;
}

.pStyle {
  font-size: 15px;
  padding: 10px;
  text-align: center;
  color: black;
}
.pStyleDiv {
  color: #c8b568;
  font-size: 20px;
  margin: 10px 0px 0px 0px;
  font-weight: 700;
}

.cryptoImage {
  transition: transform 0.3s;
}

.columnConnection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px 15px 15px;
  height: inherit;
}

.gridElements {
  display: grid;
  width: inherit;
  height: inherit;
}

.gridIcon {
  height: 40px;
  width: 40px;
}

.gridDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.gridDiv:hover {
  filter: opacity(0.8);
}

.gridDiv.position {
  grid-row: 1;
}

.formControl {
  width: 300px;
}

.transferDiv {
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-cornflowerblue);
  box-sizing: border-box;
  width: 431px;
  height: 41px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: space-between;
  mix-blend-mode: normal;
}

.loader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.loaderParent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.columnConnectionLoading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px 15px 15px;
  height: 400px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
