.specific_page{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    background-color: #222737 !important;
    animation: backgroundAnimation 5s linear infinite;
}

.watermark {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.parentDiv {
    position: relative;
    border-radius: 0.75rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 450px;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    color: #333333;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.icon{
    height: 100px;
    width: 100px;
}

.h2Style{
    text-align: center;
    
}

.pStyle{
    text-align: center;
}