.faq2 {
  line-height: 70%;
  text-transform: uppercase;
}
.alcuneRisposteAlle {
  margin: 0;
  font-size: 11.88px;
  line-height: 162.5%;
  color: var(--color-white);
}
.cheCosIl,
.faqAlcuneRisposteContainer {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}
.faqAlcuneRisposteContainer {
  letter-spacing: 0.02em;
  text-align: center;
  width: 531.09px;
  height: 80px;
  font-size: var(--font-size-6xl);
  color: var(--color-darkkhaki);
}
.cheCosIl {
  letter-spacing: 0.04em;
  line-height: 135%;
  font-weight: 600;
  width: 482.64px;
  height: 10.05px;
  z-index: 0;
}
.frecciaGiuIcon {
  position: absolute;
  margin: 0 !important;
  top: -1.37px;
  left: 473.96px;
  border-radius: 7.31px;
  width: 7.31px;
  height: 10.05px;
  z-index: 1;
}
.domandaIcon,
.primaDomanda {
  display: flex;
  flex-direction: column;
}
.domandaIcon {
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
.primaDomanda {
  border-radius: 7.31px;
  border: 0.9px solid var(--color-white);
  box-sizing: border-box;
  width: 532px;
  padding: var(--padding-base) var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  height: inherit;
}
.ilCryptowalletSelfCustody {
  margin-block-start: 0;
  margin-block-end: 2.74px;
}

.accordionSettingButton{
  background-color: #1e5caa;
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}


.accordionSetting{
  position: relative;
    display: flex;
    align-items: center;
    width: 550px;
    font-size: small;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border-radius: 10px;
    border: 0;
    margin-bottom: 0rem;
}
@media  (max-width: 1290px) {
  .accordionSetting{
    width: 484px;
  }

  .accordionItem{
    width: min-content;
  }
}

@media (max-width: 1256px){
.accordionSetting {
    width: 430px;
}

.accordionItem{
  width: min-content;
}
}


.ilCryptowalletSelfCustodyContainer {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 170%;
  display: inline-block;
  width: 404.94px;
  height: 118.83px;
  flex-shrink: 0;
}
.domandaFaq,
.faq,
.primaRisposta {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.primaRisposta {
  display: none;
  max-width: 0;
  transition: max-width 0.3s ease-in-out;
}
.primaRisposta.opened {
  display: flex;
  max-width: 100%;
}
.domandaFaq {
  height: 42px;
  overflow: hidden;
  flex-shrink: 0;
  gap: var(--gap-sm);
}
.faq {
  width: 532px;
  height: inherit;
  padding: 30px 0px 0px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 15px;
  text-align: left;
  font-size: 11.88px;
  color: var(--color-white);
  font-family: var(--font-poppins);
  flex-direction: column;
}
