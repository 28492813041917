.base {
  height: 83.12%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 16.88%;
  left: 0;
}
.base,
.icon,
.support {
  position: absolute;
}
.support {
  top: calc(50% - 26.8px);
  left: 30.98%;
  letter-spacing: 1px;
  line-height: 19.5px;
  text-transform: uppercase;
  font-weight: 600;
}
.icon {
  height: 19.56%;
  width: 16.08%;
  top: 12.52%;
  right: 41.96%;
  bottom: 67.92%;
  left: 41.96%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shadow {
  border-radius: 4.87px;
}
.background,
.fill,
.shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fill {
  border-radius: 4.87px;
  border: 1.2px solid var(--color-darkkhaki);
  box-sizing: border-box;
}
.background {
  overflow: hidden;
}
.icon1 {
  position: absolute;
  top: calc(50% - 4.27px);
  left: 90.78%;
}
.content,
.label {
  position: absolute;
  top: calc(50% - 6.7px);
}
.label {
  left: 1.42%;
  width: inherit;
  font-size: 9.75px;
  font-family: var(--font-open-sans);
}
.content {
  left: calc(50% - 42.35px);
  width: 85.92px;
  height: 13.41px;
  overflow: hidden;
}
.button {
  position: absolute;
  height: calc(100% - 155.96px);
  width: calc(100% - 110.29px);
  top: 155.96px;
  right: 53.62px;
  bottom: 0;
  left: 56.67px;
  cursor: pointer;
  font-size: 8.53px;
  font-family: var(--font-font-awesome-5-free);
}
.checkOutHelpful {
  position: absolute;
  width: 91.17%;
  top: calc(50% + 3.83px);
  left: 4.25%;
  font-size: var(--font-size-xs);
  line-height: 17.23px;
  color: var(--color-white);
  display: inline-block;
  height: 52px;
}
.item {
  position: relative;
  width: 219.37px;
  height: 180.34px;
  text-align: center;
  font-size: 14.62px;
  color: var(--color-darkkhaki);
  font-family: var(--font-poppins);
}
