.carousel {
    height: 180px;
    width: -webkit-fill-available;
    position: relative;
  }
  
  .carouselItem {
    height: 60px;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 1s;
  }
  
  .carouselItem.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .carouselItem.hidden {
    opacity: 0;
    transform: translateY(100%);
  }


@media screen and (min-width: 1520px) and (max-width: 1750px) {
  .carousel{
  font-size: 16px;
  }
}
  