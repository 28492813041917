.account1,
.xe235e24 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 100%;
}

.coinType {
  font-size: 15px;
  line-height: 100%;
  color: #c8b568;
  font-family: var(--font-poppins);
}
.account1 {
  font-weight: 600;
  font-family: var(--font-inter);
  z-index: 0;
}
.xe235e24 {
  display: inline-block;
  width: 82px;
  flex-shrink: 0;
}
.vectorIcon {
  position: relative;
  width: 14px;
  height: 16px;
}
.xe235e24Parent {
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  z-index: 1;
  font-size: var(--font-size-smi);
  font-family: var(--font-inter);
}
.portfolio {
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: 0.03em;
  text-align: center;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 2;
}
.active {
  position: relative;
  background-color: var(--color-darkkhaki);
  width: 19px;
  height: 2px;
  z-index: 3;
}
.vectorIcon1 {
  position: relative;
  width: 23px;
  height: 32px;
}
.bitcoin,
.btc {
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
}
.bitcoin {
  line-height: 20px;
  font-weight: 600;
  width: 102px;
}
.btc {
  line-height: 16px;
  color: var(--color-gray-300);
  width: 62px;
}
.bitcoinParent,
.title {
  display: flex;
  align-items: flex-start;
}
.bitcoinParent {
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-11xs);
}
.title {
  flex-direction: row;
  justify-content: flex-start;
}
.btc023455,
.usd3098023 {
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
  width: 123px;
}
.btc023455 {
  line-height: 20px;
  font-weight: 600;
}
.usd3098023 {
  line-height: 16px;
  color: var(--color-gray-300);
}
.btc023455Parent {
  width: 107px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-11xs);
  text-align: right;
  font-size: var(--font-size-smi);
}
.bitcoinTrans {
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xl) 0 0 var(--padding-7xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-smi);
  z-index: 4;
}
.vectorIcon2 {
  position: relative;
  width: 25px;
  height: 40px;
}
.ethereum {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 103px;
}
.ethTrans {
  width: 264px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-8xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  z-index: 5;
}
.vectorIcon3 {
  position: relative;
  width: 33.25px;
  height: 33.25px;
}
.bnb {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 83px;
}
.bnbTrans,
.title2 {
  width: 103px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.bnbTrans {
  width: 264px;
  align-items: center;
  gap: var(--gap-3xs);
  z-index: 6;
}
.bilancioTotale,
.usd11900204 {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 20px;
}
.usd11900204 {
  font-size: var(--font-size-sm);
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--color-darkkhaki);
  text-align: right;
}
.bilancio {
  width: 260px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-6xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-lg);
  z-index: 7;
  font-size: var(--font-size-smi);
}

.flexDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  width: 150px;
}

.flexDiv2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.divider {
  position: relative;
  background-color: var(--color-aliceblue);
  width: 250px;
  height: 2px;
  mix-blend-mode: overlay;
}
.dividerWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xl) var(--padding-3xs) var(--padding-12xl);
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 8;
}
.attivitRecenti1 {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ethereum1 {
  margin: 0;
  font-weight: 600;
}
.ricevuti {
  margin: 0;
  font-size: var(--font-size-2xs);
  font-weight: 300;
}
.ethereumRicevuti {
  padding-left: 10px;
  left: 40.08px;
  letter-spacing: 0.03em;
  line-height: 130%;
}
.eth1 {
  line-height: 100%;
  font-weight: 600;
  text-align: right;
}
.listviewActivityChild {
  border-radius: 50%;
  border: 0.7px solid var(--color-lightsteelblue);
  box-sizing: border-box;
  width: 32.8px;
  height: 32.8px;
}
.harukiIcons {
  padding: 6px;
}
.confermato,
.addressFrom,
.addressTo {
  line-height: 11.66px;
}
.addressFrom {
  color: var(--color-gray-300);
}

.addressTo {
  color: var(--color-gray-300);
}
.confermato {
  top: 32.49px;
  left: 48.83px;
  font-size: var(--font-size-2xs-2);
  letter-spacing: 0.01em;
}
.listviewActivityItem {
  border-radius: 50%;
  background-color: var(--color-mediumspringgreen);
  width: 4.37px;
  height: 4.37px;
}

.listviewActivityItem2 {
  border-radius: 50%;
  background-color: var(--color-orange);
  width: 4.37px;
  height: 4.37px;
}
.listviewActivity {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-evenly;
  padding: 10px 10px 10px 20px;
  z-index: 1;
}
.bnb3,
.bnbRicevuti {
  position: absolute;
  top: 0;
}
.bnbRicevuti {
  left: 40.08px;
  letter-spacing: 0.03em;
  line-height: 113%;
}
.bnb3 {
  left: 157.35px;
  line-height: 100%;
  font-weight: 600;
  text-align: right;
}
.listviewActivity1 {
  position: relative;
  width: 264.35px;
  height: 44.49px;
  z-index: 2;
}
.inviati {
  margin: 0;
}
.btc1,
.usd100000 {
  position: absolute;
  text-align: right;
}
.btc1 {
  top: 0;
  left: 166.37px;
  line-height: 100%;
  font-weight: 600;
}
.usd100000 {
  top: 17.49px;
  left: 193.61px;
  line-height: 11.66px;
  color: var(--color-gray-300);
}
.listviewActivityChild2 {
  position: absolute;
  top: 36.14px;
  left: 40.08px;
  border-radius: 50%;
  background-color: var(--color-orange);
  width: 4.37px;
  height: 4.37px;
}
.divider1,
.listviewActivity2 {
  position: relative;
  width: 264.61px;
  height: 44.49px;
  z-index: 3;
}
.divider1 {
  position: relative;
  background-color: var(--color-aliceblue);
  width: 250px;
  height: 2px;
  mix-blend-mode: overlay;
}
.active1 {
  position: absolute;
  margin: 0 !important;
  top: 31px;
  left: 122.8px;
  background-color: var(--color-darkkhaki);
  width: 19px;
  height: 2px;
  z-index: 5;
}
.maggio10,
.maggio8,
.marzo3 {
  position: absolute;
  margin: 0 !important;
  top: 51px;
  left: 98.3px;
  font-size: var(--font-size-smi);
  letter-spacing: 0.03em;
  line-height: 100%;
  z-index: 6;
}
.maggio8,
.marzo3 {
  top: 148.09px;
  left: 100.3px;
  z-index: 7;
}
.marzo3 {
  top: 246.98px;
  left: 105.8px;
  z-index: 8;
}
.attivitRecenti {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  position: relative;
  gap: var(--gap-34xl);
  z-index: 9;
  font-size: var(--font-size-xs);
}
.frecciaGiuIcon {
  position: absolute;
  margin: 0 !important;
  top: 62px;
  left: 271px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  z-index: 10;
}
.sidebarRight {
  border-radius: var(--br-mini);
  background-color: var(--color-gray-400);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-sm);
  mix-blend-mode: normal;
  z-index: 3;
  margin-right: 15px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-poppins);
}

.firstAddresses {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pStyle {
  text-align: center;
  margin: 0px;
}

@media screen and (min-width: 1520px) and (max-width: 1750px) {
  .sidebarRight {
    width: 400px;
    height: 890px;
  }
  .account1 {
    font-size: 20px;
  }
  .frecciaGiuIcon {
    top: 70px;
    left: 315px;
    width: 12px;
    height: 12px;
  }
  .xe235e24 {
    width: unset;
    font-size: 18px;
  }
  .coinType {
    font-size: 18px;
  }
  .active {
    width: 40px;
    height: 3px;
  }
  .portfolio {
    text-align: center;
  }
  .pStyle {
    font-size: 15px;
  }
  .vectorIcon1 {
    width: 30px;
    height: 45px;
  }
  .btc023455Parent {
    width: 150px;
  }
  .btc023455{
    font-size: 18px;
  }
  .listviewActivityItem2{
    width: 6px;
    height: 6px;
  }
  .listviewActivityItem{
    width: 6px;
    height: 6px;
  }
  .ricevuti{
    font-size: 15px;
  }
  .listviewActivityChild{
    width: 40px;
    height: 40px;
  }
  .harukiIcons{
    width: 38px;
    height: 33px;
    padding: 2px;
  }
}
