.connectionCard {
  z-index: 2;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-top: 45px;
  align-items: center;
  background: whitesmoke;
  height: 330px;
  width: 320px;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 30px;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

.spanStyle {
  color: #c8b568;
  font-weight: 900;
}

.containerCard {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: 4;
}

.containerCard.blurOut {
  z-index: -1;
}

.connectionCard.fadeOut {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
  z-index: 0;
}

.connectionCard.connecting {
  height: 310px;
}

.firstRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  width: inherit;
  border-bottom: 1px solid #d4cfcf;
  background: linear-gradient(130deg, #7ba2de, #eaf2ff);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.firstRowH1 {
  font-size: 18px;
  margin-right: 80px;
}

.firstRowIcon {
  height: 20px;
  width: 20px;
}

.firstRowIcon:hover {
  cursor: pointer;
  color: #000000a8;
}

.rowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: inherit;
  justify-content: space-between;
}
.rowConnection > div:nth-child(1) {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-left: 23px;
  height: 20px;
  font-size: 20px;
  color: #c8b568;
  font-weight: 900;
}

.rowConnection > div:nth-child(2) {
  flex: 0;
}

.secondRowConnection {
  display: flex;
  flex-direction: row;
  padding: 1em;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
  border: solid 1px #6c6c6c;
  cursor: pointer;
  border-radius: 15px;
}
.secondRowConnection.alt {
  border: solid 1px #6c6c6c;
  background-color: rgb(213 214 218);
  justify-content: space-between;
  height: 70px;
}

.secondRowConnection:hover .cryptoImage {
  transform: rotate(45deg);
}

.flexDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyPopup {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); 
  width: max-content;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  z-index: 999;
}

.flexDivSecond {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.iconStyle {
  cursor: pointer;
}

.flexDiv.cont {
  align-items: center;
  border: solid 1px #6c6c6c;
  background-color: rgb(213 214 218);
  border-radius: 15px;
}

.pStyle {
  font-size: 15px;
  padding: 10px;
  text-align: center;
}
.cryptoImage {
  transition: transform 0.3s;
}

.columnConnection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px 15px 15px;
  height: inherit;
}

.gridElements {
  display: grid;
  width: inherit;
  height: inherit;
}

.gridIcon {
  height: 40px;
  width: 40px;
}

.gridDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.gridDiv:hover {
  filter: opacity(0.8);
}

.gridDiv.position {
  grid-row: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
